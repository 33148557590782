import React from "react"
import { Link, graphql } from "gatsby"

import Swiper from "../components/swiper"
import IndexLayout from "../components/indexlayout"
import SEO from "../components/seo"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"
import Speakerlist from "../components/speakerlist"
import Program from "../components/program"
import image from "../../static/globart-plakat-rgb.jpg"
import SliderSinn from "../components/slidersinn"
import Sponsors from "../components/sponsors"

const Plakat = styled.img`
  position: absolute;
  width: 30%;
  max-width: 350px;
  left: 0;
  top: 3rem;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`
const RueckblickHeader = styled.h1`
padding-top: 7rem;
text-align: center;
`

const SwiperContainer = styled.div`
max-width: 1200px; 
margin: 6rem auto 0 auto;
`
const StyledSwiper = styled(Swiper)`
  padding-bottom: 3rem;
`

const VideoContainer = styled.div`
width: 90vw;
margin: 9rem auto 0 auto;
max-width: 1200px;
max-height: 675px;
`
const IframeContainer = styled.div`
overflow: hidden;
padding-top: 56.25%;
position: relative;
height: 0;
`
const StyledIframe = styled.iframe`
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
`

const FotoLinkContainer = styled.div`
display: flex;
  justify-content: center;
`
const FotoLink = styled(Link)`
  color: white !important;
  box-sizing: border-box;
  margin-top: 6rem;
  display: inline-block;
  text-decoration: none;
  background: #6594d4;
  padding: 1rem;
  font-size: 20px;
`

const Content = styled.div`
  max-width: 1200px;
  position: relative;
  margin: 6rem auto 0 auto;
  display: flex;
  flex-direction: row-reverse;
  scroll-behavior: smooth;
  p {
    word-break: normal;
    hyphens: auto;
    margin-bottom: 1.45em;
  }
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
  }
`
const TicketLink = styled(Link)`
  color: white !important;
  box-sizing: border-box;
  margin-top: 1rem;
  display: inline-block;
  text-decoration: none;
  background: #6594d4;
  padding: 1rem;
  font-size: 20px;
`
const Content2 = styled.div`
  max-width: 1200px;
  margin: 6.5rem auto 0 auto;
  scroll-behavior: smooth;
  padding-top: 8rem;
  padding: 0 1rem;
  p {
    word-break: normal;
    hyphens: auto;
  }
`

const Parlament = styled.div`
  padding: 0 1rem;
  position: relative;
  width: 66%;
  margin-top: 4rem;
  margin-left: auto;
  h2:first-of-type {
    display: inline-block;
  }
  h2:last-of-type {
    float: right;
    display: inline-block;
  }
  @media only screen and (max-width: 1080px) {
    width: 100%;
    h2:first-of-type {
      display: inherit;
    }
    h2:last-of-type {
      float: none;
      display: inherit;
    }
  }
`

const Info = styled.div`
  padding: 0 1rem 0 1rem;
  align-self: flex-end;
  color: #6594d4;
  width: 33%;
  margin-bottom: 11.7em;
  background: transparent;
  h2 {
    margin-top: 48px;
    font-size: 2em;
    line-height: 1.45em;
    background: transparent;
    font-family: "Marr Sans Web";
  }
  a {
    color: #6594d4;
  }
  @media only screen and (max-width: 1080px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    transform: translateY(0);
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h2 {
      margin-bottom: 0.62em;
    }
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
const Heading = styled.h1`
  padding: 1rem;
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const data = this.props.data.prismic.allEventseriess.edges[0].node
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }
    return (
      <IndexLayout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY 2021 IM STIFT MELK"
          description="Die GLOBART Academy Triade leben-macht-SINN ist zurück!"
          keywords={[
            "GLOBART Academy",
            "Melk",
            "Kunst",
            "Stephan A.Jansen",
            "Friedrich von Borries",
            "Kultur",
          ]}
        />
        <SliderSinn />

        <RueckblickHeader>
          Das war die GLOBART Academy 2021
        </RueckblickHeader>
        <SwiperContainer>
         <StyledSwiper slider={data.slider} />
        </SwiperContainer>

        <VideoContainer>  
          <IframeContainer>
          <StyledIframe src="https://www.youtube.com/embed/mwPnMxxqyrE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></StyledIframe>
          </IframeContainer>
        </VideoContainer>

        <FotoLinkContainer>
        <FotoLink to="https://www.flickr.com/photos/globart/albums">Zu den Bildern auf Flickr → </FotoLink>
        </FotoLinkContainer>

        <Content id="top">
          <Parlament id="akw">{RichText.render(data.body)}</Parlament>
          <Info>
            <h2>
              02.09. – 04.09.2021
              <br />
              Stift Melk
            </h2>

            <p>
              Mit einer <Link to="/sehgal">Ausstellung von Tino Sehgal</Link>{" "}
              und dem{" "}
              <Link to="/stipendium">
                {" "}
                Stipendienprojekt STADT-SINN ODER SIN-CITY? geleitet von Stephan
                A. Jansen und Martha Wanat
              </Link>
            </p>
            <TicketLink to="/tickets">Zu den Tickets → </TicketLink>
          </Info>
        </Content>
        <Content2>
          <Program events={data.events} />
          <Speakerlist heading="Mitwirkende" speakers={data.speakers} />
          <Sponsors lang="de-at" />
        </Content2>
        
      </IndexLayout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query SinnEnQuery {
    prismic {
      allEventseriess(lang: "de-at", id: "YEc6gRIAACMAsgOH") {
        edges {
          node {
            slider {
              image
              slidebody
            }
            body
            speakers {
              speaker {
                ... on PRISMIC_Speaker {
                  title
                  description
                  thumbnail
                  quote
                  _meta {
                    uid
                    id
                  }
                }
              }
            }
            events {
              event {
                ... on PRISMIC_Event {
                  title
                  subtitle
                  end_time
                  start_time
                  formatdescription
                  description
                  allday
                  musik
                  thumbnail
                  moderation {
                    ... on PRISMIC_Speaker {
                      title
                      excerpt
                      role
                      angefragt
                      _meta {
                        uid
                      }
                    }
                  }
                  speaker {
                    ... on PRISMIC_Speaker {
                      title
                      excerpt
                      role
                      angefragt
                      _meta {
                        uid
                      }
                    }
                  }
                  musicians {
                    musiker {
                      ... on PRISMIC_Speaker {
                        title
                        excerpt
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  speakers {
                    additional_speakers {
                      ... on PRISMIC_Speaker {
                        title
                        excerpt
                        role
                        angefragt
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  _meta {
                    uid
                    lang
                  }
                  location
                  parallel
                  eventlocation
                }
              }
            }
          }
        }
      }
    }
  }
`
